import classes from "./RegistrationBar.module.css";
import { Link } from "react-router-dom";
import {Toolbar} from "@material-ui/core";
import ChequeScannerLogo from "../../../Assets/cheque_scanner_logo.png"
import React from "react";
const RegistrationBar = (props) => {
  const signup = (
    <>
    <div className={classes.logo} style={{paddingTop: "0"}}>
        <a
            href="https://www.routingnumbers.ca/"
            style={{ textDecoration: "none" }}
        >
            <div style={{display: "flex", alignItems: "middle", marginTop: "20px", marginLeft: "30px"}}>
                <img src={ChequeScannerLogo} width="30px" height="30px" alt="Logo" style={{display: "inline", marginTop: "20px"}} />
                <h2 style={{display: "inline", marginTop: "4px", paddingTop:"20px", paddingLeft: "5px"}}>routingnumbers.ca</h2>
            </div>
        </a>
    </div>
      <div className={classes.content}>
        <h3>Create new Account</h3>
        <p>
          Already Registered?{" "}
          <Link to="/login">
            <span className={classes.logSign}>Login</span>
          </Link>
        </p>
      </div>
    </>
  );
  const login = (
    <>
    <div className={classes.logo} style={{paddingTop: "0"}}>
        <a
            href="https://www.routingnumbers.ca/"
            style={{ textDecoration: "none" }}
        >
            <div style={{display: "flex", alignItems: "middle", marginTop: "20px", marginLeft: "30px"}}>
                <img src={ChequeScannerLogo} width="30px" height="30px" alt="Logo" style={{display: "inline", marginTop: "20px"}} />
                <h2 style={{display: "inline", marginTop: "4px", paddingTop:"20px", paddingLeft: "5px"}}>routingnumbers.ca</h2>
            </div>
        </a>
    </div>
      <div className={classes.content}>
        <h3>Login</h3>
        <p>
          Don't have an account yet?{" "}
          <Link to="/signup">
            <span className={classes.logSign}>Sign up</span>
          </Link>
        </p>
      </div>
    </>
  );
  return (
    <div className={classes.border}>
      {props.incoming === "login" ? login : signup}
    </div>
  );
};
export default RegistrationBar;
