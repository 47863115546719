import React, { useEffect, useState } from "react";
import firebaseConfig from "../config";
import Loader from "react-loader-spinner";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    firebaseConfig.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return <div className="loading-bg">
      <Loader
        type="Grid"
        color="#ff4f63"
        height={100}
        width={100}
      />
    </div>;
  }
  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
