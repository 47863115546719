import firebase from "firebase";
import "firebase/auth";

const firebaseConfig = firebase.initializeApp({
  apiKey: "AIzaSyDbRHJxWSJAtEww2aZ1XJgwb1f-d4W8Eww",
  authDomain: "routing-numbers-web.firebaseapp.com",
  projectId: "routing-numbers-web",
  storageBucket: "gs://routing-numbers-web.appspot.com",
  databaseURL: "https://routing-numbers-web-default-rtdb.firebaseio.com/",
  messagingSenderId: "956448619390",
  appId: "1:956448619390:web:0fbabc5c161b74e8cb512f"
});
export const auth = firebaseConfig.auth();
export const db = firebase.database();
export default firebaseConfig;
